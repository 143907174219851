<template>
	<div>
		<div class="orderbox">
			<div class="orderboxup">
				<div class="boxtitle d-flex justify-content-between">
					<p class="tip-title">全部订单</p>
				</div>
				<div class="orderboxlist">
					<ul>
						<li>
							<a href="javascript:void(0);">
								<div class="orderboxlistright"><i class="el-icon-s-finance"></i></div>
								<div class="orderboxlistleft">
									<p>待付款</p>
									<h5>4</h5>
								</div>
							</a>
						</li>
						<li>
							<a href="javascript:void(0);">
								<div class="orderboxlistright"><i class="el-icon-s-management"></i></div>
								<div class="orderboxlistleft">
									<p>待发货</p>
									<h5>1073</h5>
								</div>
							</a>
						</li>
						<li>
							<a href="javascript:void(0);">
								<div class="orderboxlistright"><i class="el-icon-s-platform"></i></div>
								<div class="orderboxlistleft">
									<p>待收货</p>
									<h5>2436</h5>
								</div>
							</a>
						</li>
						<li>
							<a href="javascript:void(0);">
								<div class="orderboxlistright"><i class="el-icon-s-cooperation"></i></div>
								<div class="orderboxlistleft">
									<p>售后中</p>
									<h5>1300</h5>
								</div>
							</a>
						</li>
					</ul>
				</div>
			</div>
			<div class="orderboxitem">
				<p>今日下单数量<span>(单)</span></p>
				<h5>0</h5>
				<div class="orderboxitemlast">
					<span>较昨日</span>
					<strong>-%</strong>
				</div>
			</div>
			<div class="orderboxitem orderboxitemed">
				<p>今日成交金额<span>(元)</span></p>
				<h5>0</h5>
				<div class="orderboxitemlast">
					<span>较昨日</span>
					<strong>-%</strong>
				</div>
			</div>
			<div class="orderboxitem orderboxitems">
				<p>今日下单人数<span>(人)</span></p>
				<h5>0</h5>
				<div class="orderboxitemlast">
					<span>较昨日</span>
					<strong>-%</strong>
				</div>
			</div>
			<div class="orderboxitem orderboxstat">
				<div class="boxtitle d-flex justify-content-between align-items-center">
					<p class="tip-title">订单数量</p>
					<div class="rankright">
						<div class="rankrighted clearfloat">
							<button class="active">最近7天</button>
							<button>最近15天</button>
							<button>最近30天</button>
						</div>
					</div>
				</div>
				<div class="orderboxnum" id="orderboxnumber"></div>
			</div>
			<div class="orderboxitem orderboxcount">
				<div class="boxtitle d-flex justify-content-between align-items-center">
					<p class="tip-title">订单金额</p>
					<div class="rankright">
						<div class="rankrighted clearfloat">
							<button class="active">最近7天</button>
							<button>最近15天</button>
							<button>最近30天</button>
						</div>
					</div>
				</div>
				<div class="orderboxmoe" id="orderboxmoeny"></div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				
			};
		},
		created() {
		},
		mounted() {
			this.orderecharted();
			this.orderecharmoney();
		},
		methods: {
			//统计
			orderecharted() {
			  let myChart = this.$echarts.init(document.getElementById("orderboxnumber"));
			  let option = {
			  	tooltip: {
			  		trigger: 'axis',
			  	},
			  	grid: {
			  		top: '5%',
			  		left: '0%',
			  		right: '6%',
			  		bottom: '5%',
			  		containLabel: true
			  	},
			  	xAxis: [{
			  		type: 'category',
			  		axisLabel: {
			  		  show: true,
			  		  textStyle: {
			  		    color: '#999'
			  		  }
			  		},
			  		axisLine: {
			  		  show:true,//是否显示轴线
			  		  lineStyle: {
			  		    color: '#fff',//刻度线的颜色
			  		  }
			  		},
			  		data: ['11-26','11-27','11-28','11-29','11-30','12-30']
			  	}],
			  	yAxis: [{
			  		type: 'value',
			  		axisLabel: {
			  		  show: true,
			  		  textStyle: {
			  		    color: '#999'
			  		  }
			  		}
			  	}],
			  	series: [{
			  		name: '订单数',
			  		type: 'bar',
			  		barMaxWidth: 15,
			  		smooth: true,
			  		itemStyle: {
						borderWidth: 3,
						barBorderRadius: [10,10,0,0],
						opacity: 0.8,
						color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
								offset: 0,
								color: '#5052EE'
							},
							{
								offset: 1,
								color: '#AB6EE5'
							}
						])
			  		},
			  		data: [658, 98, 418, 108, 98, 78]
			  	}]
			  };
			  myChart.setOption(option);
			},
			orderecharmoney() {
			  let myChart = this.$echarts.init(document.getElementById("orderboxmoeny"));
			  let option = {
			  	tooltip: {
			  		trigger: 'axis',
			  	},
			  	grid: {
			  		top: '5%',
			  		left: '0%',
			  		right: '6%',
			  		bottom: '5%',
			  		containLabel: true
			  	},
			  	xAxis: [{
			  		type: 'category',
			  		axisLabel: {
			  		  show: true,
			  		  textStyle: {
			  		    color: '#999'
			  		  }
			  		},
			  		axisLine: {
			  		  show:true,//是否显示轴线
			  		  lineStyle: {
			  		    color: '#fff',//刻度线的颜色
			  		  }
			  		},
			  		data: ['01-26','01-27','01-28','01-29','01-30','02-01']
			  	}],
			  	yAxis: [{
			  		type: 'value',
			  		axisLabel: {
			  		  show: true,
			  		  textStyle: {
			  		    color: '#999'
			  		  }
			  		}
			  	}],
			  	series: [{
			  		name: '订单金额',
			  		type: 'bar',
			  		barMaxWidth: 15,
			  		smooth: true,
			  		itemStyle: {
						barBorderRadius: [10,10,0,0],
						opacity: 0.8,
						color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
								offset: 0,
								color: '#f8c291'
							},
							{
								offset: 1,
								color: '#b71540'
							}
						])
			  		},
			  		data: [888, 668, 520, 18, 124, 78]
			  	}]
			  };
			  myChart.setOption(option);
			}
		}
	};
</script>
<style scoped>
	@import url("css/order.css");
</style>